<template>
  <CommonPage id="privacy" title="Privacy policy" classs="blog ">
    <section id="privacy-content">
      <div class="common-content-row">
        At {{ domainConfig["IAmURL"] }}, we are committed to protecting your privacy. This Privacy
        Policy explains how we
        collect, use, and safeguard your information.
      </div>

      <div class="common-content-head">
        Information We Collect
      </div>
      <div class="common-content-row">
        We do not require you to create an account or provide personal information to use our site.
        However, we may collect non-personally identifiable information such as browser type,
        operating system, and access times for site analytics.
      </div>

      <div class="common-content-head">
        Use of Information
      </div>
      <div class="common-content-row">
        The non-personally identifiable information we collect is used to improve the functionality
        and user experience of {{ domainConfig["IAmURL"] }}. We do not share or sell this
        information to third parties.
      </div>

      <div class="common-content-head">
        Cookies
      </div>
      <div class="common-content-row">
        We may use cookies to enhance your experience on our site. Cookies are small files stored on
        your device that help us understand how you use our site. You can disable cookies through
        your browser settings.
      </div>

      <div class="common-content-head">
        Data Security
      </div>
      <div class="common-content-row">
        We implement reasonable security measures to protect the information we collect. However, no
        transmission over the internet is completely secure, and we cannot guarantee absolute
        security.
      </div>

      <div class="common-content-head">
        Changes to Privacy Policy
      </div>
      <div class="common-content-row">
        We may update this Privacy Policy from time to time. Any changes will be posted on this
        page, and your continued use of {{ domainConfig["IAmURL"] }} constitutes acceptance of such
        changes.
      </div>

      <div class="common-content-head">
        Contact Us
      </div>
      <div class="common-content-row">
        If you have any questions about this Privacy Policy, please contact us at
        {{ domainConfig['IAMEMAIL'] }}.
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/m_common.vue';

import '@/css/common.scss';

export default {
  name: 'PrivacyMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "domainConfig"

    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
  }
}
</script>
